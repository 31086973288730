<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="国家名" prop="name">
        <el-input v-model="form.name" size="small" readonly />
      </el-form-item>
      <el-form-item
        style="margin-bottom: 20px"
        label="国家名(中文)"
        prop="name_cn"
      >
        <el-input v-model="form.name_cn" size="small" readonly />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="国家编码" prop="code">
        <el-input v-model="form.code" size="small" readonly />
      </el-form-item>
      <el-form-item
        style="margin-bottom: 20px"
        label="省份"
        prop="has_province"
      >
        <el-switch
          v-model="form.has_province"
          active-color="#13ce66"
          active-text="有"
          :active-value="1"
          inactive-color="#ff4949"
          inactive-text="无"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="城市" prop="has_city">
        <el-switch
          v-model="form.has_city"
          active-color="#13ce66"
          active-text="有"
          :active-value="1"
          inactive-color="#ff4949"
          inactive-text="无"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="区" prop="has_district">
        <el-switch
          v-model="form.has_district"
          active-color="#13ce66"
          active-text="有"
          :active-value="1"
          inactive-color="#ff4949"
          inactive-text="无"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="乡镇" prop="has_town">
        <el-switch
          v-model="form.has_town"
          active-color="#13ce66"
          active-text="有"
          :active-value="1"
          inactive-color="#ff4949"
          inactive-text="无"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="地区排序" prop="sort">
        <el-switch
          v-model="form.sort"
          active-color="#13ce66"
          active-text="正序"
          :active-value="1"
          inactive-color="#ff4949"
          inactive-text="倒序"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否营业" prop="is_biz">
        <el-switch
          v-model="form.is_biz"
          active-color="#13ce66"
          :active-value="1"
          inactive-color="#ff4949"
          :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiUpdateCountry } from "@/request/api";

export default {
  name: "countrySettingsEdit",
  data() {
    return {
      coustate: true,
      query_time: [],
      apiRegion: [],
      form: {
        id: "",
        name: "",
        name_cn: "",
        code: "",
        has_province: 1,
        has_city: 1,
        has_district: 1,
        has_town: 1,
        sort: 1,
        is_biz: 1,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "国家名称不能为空" },
        ],
        name_cn: [
          { required: true, trigger: "blur", message: "国家名称不能为空" },
        ],
        code: [
          { required: true, trigger: "blur", message: "国家编码不能为空" },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = "添加国家";
      } else {
        this.title = "修改国家";
        this.form = Object.assign({}, row);
      }
      this.dialogFormVisible = true;
    },
    selectionStatus(event) {
      console.log(event);
      if (event == 2) {
        this.coustate = true;
      } else {
        this.coustate = false;
      }
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          apiUpdateCountry(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$emit("fetch-data");
              this.close();
            } else {
              this.$message.error(res.message);
              this.close();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
