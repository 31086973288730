<template>
  <div class="page">
    <div style="display: flex; align-items: center; justify-content: flex-end">
      <!--      <el-button-->
      <!--        class="button"-->
      <!--        icon="el-icon-plus"-->
      <!--        type="primary"-->
      <!--        size="small"-->
      <!--        @click="handleEdit"-->
      <!--        >新增国家</el-button-->
      <!--      >-->
      <el-form style="display: flex" label-width="80px" label-position="right">
        <el-form-item label="国家名称:" style="margin: 0; margin-right: 10px">
          <el-input
            v-model="queryForm.keyword"
            placeholder="请输入国家名称（中文）"
          ></el-input>
        </el-form-item>
        <el-form-item label="洲:" style="margin: 0; margin-right: 10px">
          <el-select v-model="queryForm.state">
            <el-option value="亚洲" label="亚洲" />
            <el-option value="非洲" label="非洲" />
            <el-option value="南美洲" label="南美洲" />
            <el-option value="大洋洲 " label="大洋洲 " />
            <el-option value="欧洲" label="欧洲" />
            <el-option value="北美洲" label="北美洲" />
          </el-select>
        </el-form-item>
        <el-form-item label="营业状态:" style="margin: 0; margin-right: 10px">
          <el-select v-model="queryForm.is_biz">
            <el-option :value="1" label="是" />
            <el-option :value="0" label="否" />
          </el-select>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="fetchData"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh-right" @click="resetHandle"
          >重置</el-button
        >
      </el-form>
    </div>

    <el-table :data="list" @selection-change="setSelectRows">
      <el-table-column align="center" prop="id" label="ID" width="80">
      </el-table-column>
      <el-table-column align="center" prop="name" label="国家">
      </el-table-column>
      <el-table-column align="center" prop="name_cn" label="国家(中文)">
      </el-table-column>
      <el-table-column align="center" prop="state" label="洲" />
      <el-table-column align="center" prop="code" label="国家编码">
      </el-table-column>
      <el-table-column align="center" label="区域架构">
        <template #default="{ row }">
          <div v-if="row.sort">
            <span v-if="row.has_province">省</span>
            <span v-if="row.has_province && row.has_city">-</span>
            <span v-if="row.has_city">城</span>
            <span v-if="(row.has_province || row.has_city) && row.has_district"
              >-</span
            >
            <span v-if="row.has_district">区</span>
            <span
              v-if="
                (row.has_province || row.has_city || row.has_district) &&
                row.has_town
              "
              >-</span
            >
            <span v-if="row.has_town">镇</span>
          </div>
          <div v-if="!row.sort">
            <span v-if="row.has_town">镇</span>
            <span v-if="row.has_town && row.has_district">-</span>
            <span v-if="row.has_district">区</span>
            <span v-if="(row.has_town || row.has_district) && row.has_city"
              >-</span
            >
            <span v-if="row.has_city">城</span>
            <span
              v-if="
                (row.has_town || row.has_district || row.has_city) &&
                row.has_province
              "
              >-</span
            >
            <span v-if="row.has_province">省</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="开通营业">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_biz"
            :active-value="1"
            :inactive-value="0"
            @change="changeCountryBiz($event, scope.$index, scope.row.id)"
          />
        </template>
      </el-table-column>
      <!--      <el-table-column align="center" prop="sort" label="排序">-->
      <!--        <template #default="{ row }">-->
      <!--          <span v-if="row.sort == 1">正序</span>-->
      <!--          <span v-else>倒序</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <!--          <el-button type="text" @click="handleDelete(row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <!--    <el-pagination
            background
            :current-page="queryForm.page"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="count"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />-->
    <Edit ref="Edit" @fetch-data="fetchData" />
  </div>
</template>
<script>
import Edit from "./components/countrySettingsEdit";
import {
  apiDeleteCountry,
  apiGetCountrysList,
  apiCountryIsBiz,
} from "@/request/api";

export default {
  components: { Edit },
  props: [],
  data() {
    return {
      imgShow: true,
      selectRows: "",
      // listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        keyword: "",
        state: "",
        is_biz: "",
      },
      current: 0,
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    resetHandle() {
      this.queryForm = {
        page: 1,
        pageSize: 10,
        keyword: "",
        state: "",
        is_biz: "",
      };
      this.fetchData();
    },
    changeCountryBiz(event, index, id) {
      apiCountryIsBiz({ id: id, is_biz: event }).then((res) => {});
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs["Edit"].showEdit(row);
      } else {
        this.$refs["Edit"].showEdit();
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm("你确定要删除当前项吗", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          apiDeleteCountry({ id: row.id }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success(res.message);
              this.fetchData();
            } else {
              this.$message.error(res.message);
            }
          });
        });
      }
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.page = val;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      apiGetCountrysList(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          // this.count = res.count
        }
      });
      this.listLoading = false;
    },
  },
};
</script>
<style>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
